/** @jsx jsx */
import { groupBy, keys, uniq } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button, Grid, Heading, jsx, Select } from 'theme-ui';
import * as yup from 'yup';
import { Connection, ContentFrontmatter, ContentNode, Edge } from '../data/content';
import { getHexType, groupByMultipleTopics, includeRelevant, includeRelevantNode } from '../data/methods';
import { useTextDirection } from '../i18n';
import { BodyText, ChipSet, ImageCard, Rows, ViewElement } from './atoms';
import { FeaturedPillarLogo, useLocalisedContent } from './featured';
import { FilterStyling, TextInput } from './joinform/elements';
import { BlockLink } from './nav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { isNullOrWhitespace } from './joinform/join-data';
import { navigate } from 'gatsby';

const dymaxiomImage = require("../images/dymaxion-dash.png")

export interface CustomSearchState {
  topicId: string
}

export const SearchBar = ({ ...props }) => {
  const schema = yup.object({ ...searchFields })

  const { register, handleSubmit, watch, errors, setValue } = useForm({
    validationSchema: schema
  });

  const inputProps = { register, schema, errors, watch };

  const onSubmit = async (data: yup.InferType<typeof schema>) => {
    const { SEARCH_TERM } = data;

    if (SEARCH_TERM != "" && !isNullOrWhitespace(SEARCH_TERM)) {
      navigate(`/search?SEARCH_TERM=${encodeURIComponent(SEARCH_TERM)}`)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} {...props}>
      <Box sx={{ display: "flex", paddingBottom: "0.5em" }}>
        <div sx={{ width: "100%" }}>
          <TextInput
            name='SEARCH_TERM'
            showLabel={false}
            showErrors={false}
            {...inputProps}
          />
        </div>
        <button type="submit" sx={{
          appearance: "none",
          background: "none",
          border: "none",
          height: "100%",
          width: "25px",
          margin: "auto",
          padding: 0,
          marginLeft: "7.5px",
          marginRight: "5px",
          cursor: "pointer",
          color: "#280000",
          ':hover': {
            color: "#FC164B"
          },
          ':active': {
            color: "#ed587b"
          }
        }}>
          <FontAwesomeIcon icon={faMagnifyingGlass} sx={{ height: "25px", width: "25px", color: "inherit" }} />
        </button>
      </Box>
    </form>
  )
}

export const SearchComponent: ViewElement<({ previews: Connection<ContentNode>, searchTerms: string, setSearchTerms: any, langInfo: any, state: CustomSearchState })> = ({
  previews,
  searchTerms,
  setSearchTerms,
  langInfo,
  state
}) => {
  const { language, t, dir } = langInfo

  const [searchResults, setSearchResults] = useState<Edge<ContentNode>[]>([])
  const [filtersState, setFiltersState] = useState({
    filters: {
      selectedTopicId: "",
      selectedRegionId: "",
      selectedSourceId: ""
    }
  })

  useEffect(() => {
    try {
      const { topicId } = state;

      if (state && topicId && topicId != "") {
        const filters = { ...filtersState.filters, selectedTopicId: topicId }
        setFiltersState({ filters })
      }
    } catch (e) {
      console.log("Something went wrong.")
    }
  }, [state])

  // const groupedArticlesByTopic = groupBy(previews.edges, (article) => {
  //   return article.node.frontmatter.topic?.id
  // })
  const { resDict: groupedArticlesByTopic, topicDict } = groupByMultipleTopics(previews.edges);
  const groupedArticlesByRegion = groupBy(previews.edges, (article) => {
    return article.node.frontmatter.region?.id
  })
  const groupedArticlesBySource = groupBy(previews.edges, (article) => {
    return article.node.frontmatter.source?.id
  })

  function sortByTitle(a, b) {
    if (a && a.title && b && b.title) {
      return a.title.localeCompare(b.title)
    } else {
      return 1
    }
  }

  const topicOptions = keys(topicDict).map((id: string) => {
    const title = useLocalisedContent(topicDict[id])?.title;
    return { id, title }
  }).sort((a, b) => sortByTitle(a, b));

  const regionOptions = keys(groupedArticlesByRegion).map((id: string) => {
    const title = useLocalisedContent(groupedArticlesByRegion[id][0].node.frontmatter.region)?.title;
    return { id, title }
  }).sort((a, b) => sortByTitle(a, b));

  const sourceOptions = keys(groupedArticlesBySource).map((id: string) => {
    const title = groupedArticlesBySource[id][0].node.frontmatter.source?.frontmatter.title;
    return { id, title }
  }).sort((a, b) => sortByTitle(a, b));

  const handleTopicChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const filters = { ...filtersState.filters, selectedTopicId: event.target.value }
    setFiltersState({ filters })
  }

  const handleRegionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const filters = { ...filtersState.filters, selectedRegionId: event.target.value }
    setFiltersState({ filters })
  }

  const handleSourceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const filters = { ...filtersState.filters, selectedSourceId: event.target.value }
    setFiltersState({ filters })
  }

  const showArticle = (node: ContentNode) => {
    const selectedTopicId = filtersState.filters.selectedTopicId;
    const selectedRegionId = filtersState.filters.selectedRegionId;
    const selectedSourceId = filtersState.filters.selectedSourceId;

    let valid = true

    if (selectedTopicId?.length) {
      let secondaryTopicIds;

      if (node.frontmatter.secondaryTopics && node.frontmatter.secondaryTopics.length > 0) {
        secondaryTopicIds = node.frontmatter.secondaryTopics.map(secondaryTopic => secondaryTopic.id);
      }

      if (node.frontmatter.topic?.id != selectedTopicId && (!secondaryTopicIds || (!secondaryTopicIds.includes(selectedTopicId)))) {
        valid = false
      } else if (!node.frontmatter.topic) {
        valid = false
      }
    }

    if (selectedRegionId?.length) {
      if (node.frontmatter.region?.id != selectedRegionId) {
        valid = false
      } else if (!node.frontmatter.region) {
        valid = false
      }
    }

    if (selectedSourceId?.length) {
      if (node.frontmatter.source?.id != selectedSourceId) {
        valid = false
      } else if (!node.frontmatter.source) {
        valid = false
      }
    }

    return valid
  }

  const SearchPreviews = (previews: Connection<ContentNode>, searchTerms: string, selectedTopicId: string) => {
    if (!isNullOrWhitespace(searchTerms)) {
      const searchTermsList = searchTerms.trim().split(/(\s+)/).filter(t => { return t != " " }).map(t => { return t.toLowerCase() });

      if (searchTermsList.length > 0) {
        const results = previews.edges.map(e => {
          const relevance = includeRelevantNode(uniq(searchTermsList), e.node, language);
          return { e, relevance };
        })
          .filter(r => r.relevance > 0)
          .sort((a, b) => b.relevance - a.relevance)
          .map(r => r.e)

        return results;
      } else {
        return [];
      }
    } else if (!isNullOrWhitespace(selectedTopicId)) {
      return shuffle(previews.edges);
    } else {
      return [];
    }
  }

  function shuffle(array: any[]) {
    let currIndex = array.length;
    let randIndex;

    while (currIndex != 0) {
      randIndex = Math.floor(Math.random() * currIndex);
      currIndex--;

      [array[currIndex], array[randIndex]] = [array[randIndex], array[currIndex]];
    }

    return array;
  }

  useEffect(() => {
    setSearchResults(SearchPreviews(previews, searchTerms, filtersState.filters.selectedTopicId));
  }, [searchTerms, filtersState.filters.selectedTopicId, language])

  return (
    <Grid gap={3} columns={[1, "2fr 1fr", "2f 1fr"]} sx={{ rowGap: "2em" }}>
      <div sx={{ order: -1 }}></div>
      <SearchBar sx={{ order: -1 }} />
      <Box sx={{ order: [1, -1, -1] }}>
        {(searchTerms.length > 0 || (filtersState && filtersState.filters && !isNullOrWhitespace(filtersState.filters.selectedTopicId))) && !isNullOrWhitespace(searchTerms) && (
          <Box sx={{ color: "#786464", fontSize: "1.5em", fontWeight: "600", borderBottom: "solid 2px #280000" }}>
            {searchResults.length.toString()} results for <span sx={{ color: "#280000" }}>{searchTerms}</span>{(filtersState.filters.selectedTopicId?.length > 0 || filtersState.filters.selectedRegionId?.length > 0 || filtersState.filters.selectedSourceId?.length > 0) && (<span>, {searchResults.filter(value => showArticle(value.node)).length} filtered results</span>)}
          </Box>
        )}
        {(searchTerms.length > 0 || (filtersState && filtersState.filters && !isNullOrWhitespace(filtersState.filters.selectedTopicId))) && isNullOrWhitespace(searchTerms) && (
          <Box sx={{ color: "#786464", fontSize: "1.5em", fontWeight: "600", borderBottom: "solid 2px #280000" }}>
            {searchResults.length.toString()} results{(filtersState.filters.selectedTopicId?.length > 0 || filtersState.filters.selectedRegionId?.length > 0 || filtersState.filters.selectedSourceId?.length > 0) && (<span>, {searchResults.filter(value => showArticle(value.node)).length} filtered results</span>)}
          </Box>
        )}
        {searchResults.filter((value) => { return showArticle(value.node) }).map(r => {
          return <SearchResult node={r.node} />
        })}
        {!(searchTerms.length > 0 || (filtersState && filtersState.filters && !isNullOrWhitespace(filtersState.filters.selectedTopicId))) && (
          <Box sx={{ color: "#786464", fontSize: "1.5em", fontWeight: "600", borderBottom: "solid 2px black" }}>
            Your results will appear <span sx={{ color: "#280000" }}>here</span>
          </Box>
        )}
      </Box>
      <Box sx={{ order: [-1, 1, 1] }}>
        <Box sx={{ color: "#280000", fontSize: "1.2em", lineHeight: "1.45em", fontWeight: "600", borderBottom: "solid 2px #280000", fontFamily: "'IBM Plex Mono', Menlo, monospace" }}>
          FILTERS
        </Box>
        <Box mt={["0", "1em", "1em"]} sx={{ flex: ['100%', '25%', '25%'] }}>
          <Select
            value={filtersState.filters.selectedTopicId}
            name='topic'
            onChange={handleTopicChange}
            sx={FilterStyling(filtersState.filters.selectedTopicId, "", "#280000", "#280000", dir, false)}
          >
            <option value="">{t('Topic')}</option>
            {topicOptions.map(o => (
              o.id != 'undefined' && <option value={o.id}>
                {o.title}
              </option>
            ))}
          </Select>
        </Box>
        <Box mt={["0", "1em", "1em"]} sx={{ flex: ['100%', '25%', '25%'] }}>
          <Select
            value={filtersState.filters.selectedRegionId}
            name='region'
            onChange={handleRegionChange}
            sx={FilterStyling(filtersState.filters.selectedRegionId, "", "#280000", "#280000", dir, false)}
          >
            <option value="">{t('Region')}</option>
            {regionOptions.map(o => (
              o.id != 'undefined' && <option value={o.id}>
                {o.title}
              </option>
            ))}
          </Select>
        </Box>
        <Box mt={["0", "1em", "1em"]} sx={{ direction: dir.isRtl ? 'rtl' : 'ltr', flex: ['100%', '25%', '25%'] }}>
          <Select
            value={filtersState.filters.selectedSourceId}
            name='source'
            onChange={handleSourceChange}
            sx={FilterStyling(filtersState.filters.selectedSourceId, "", "#280000", "#280000", dir, true)}
          >
            <option value="">{t('Source')}</option>
            {sourceOptions.map(o => (
              o.id != 'undefined' && <option value={o.id}>
                {o.title}
              </option>
            ))}
          </Select>
        </Box>
      </Box>
    </Grid>
  )
}

export const SearchResult: ViewElement<{ node: ContentNode }> = ({ node }) => {
  const { title, path, shortAbstract, abstract, locale } = useLocalisedContent(node)
  const dir = useTextDirection(locale)

  return (
    <Grid className='hover-parent' gap={[1, 4]} columns={[1, "5fr 8fr", "5fr 8fr"]} sx={{ marginTop: "2em", marginBottom: "2em" }}>
      <BlockLink to={path}>
        <ImageCard
          gradient={node.fields.themeColor}
          src={
            !node.frontmatter.featuredImage
              ? dymaxiomImage
              : node.frontmatter.featuredImage.childImageSharp
          }
        >
          <div
            sx={{
              display: "flex",
              height: 200,
              p: [2, null, 3],
              boxSizing: "border-box",
              justifyContent: "flex-end",
              alignItems: "flex-start",
            }}
          >
            <FeaturedPillarLogo type={node.fields.type} size={30} />
          </div>
        </ImageCard>
      </BlockLink>

      <BlockLink to={path}>
        <Rows gap={2} sx={{ textAlign: dir.left }}>
          <ChipSet node={node} dirFlexRow={dir.flexRow} color={getHexType(node.fields.type)} />
          <Heading variant='headings.5' sx={{ '.hover-parent:hover &': { color: node.fields?.themeColor } }}>
            {title}
          </Heading>
          <BodyText sx={{ '.hover-parent:hover &': { color: node.fields?.themeColor } }}>{shortAbstract || abstract}</BodyText>
        </Rows>
      </BlockLink>
    </Grid>
  )
}

export const NavSearch: ViewElement<{}> = ({ }) => {
  const [searchOpen, setSearchOpen] = useState<boolean>(false);

  const schema = yup.object({ ...searchFields });

  const { register, handleSubmit, watch, errors, setValue } = useForm({
    validationSchema: schema
  })

  const inputProps = { register, schema, errors, watch };

  const onSubmit = async (data: yup.InferType<typeof schema>) => {
    const { SEARCH_TERM } = data;

    if (SEARCH_TERM != "" && !isNullOrWhitespace(SEARCH_TERM)) {
      navigate(`/search?SEARCH_TERM=${encodeURIComponent(SEARCH_TERM)}`)
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}
        sx={{ backgroundColor: ["white", "white", (searchOpen ? "white" : "#280000")] }}
      >
        <Box sx={{ display: "flex", paddingRight: ["7.5px", "7.5px", (searchOpen ? "3.4px" : "7.5px")] }}>
          <div
            sx={{ position: "absolute", height: "25px", width: "35px", cursor: "pointer", display: ["block", "block", (!searchOpen ? "block" : "none")] }}
            onClick={() => { if (!searchOpen) { setSearchOpen(true) } }}
          >
          </div>
          <div sx={{ display: ["block", "block", (searchOpen ? "block" : "none")] }}>
            <TextInput
              name='SEARCH_TERM'
              showLabel={false}
              showErrors={false}
              {...inputProps}
            />
          </div>
          <button type="submit" sx={{
            appearance: "none",
            background: "none",
            border: "none",
            height: "20px",
            width: "20px",
            margin: "auto",
            padding: 0,
            marginLeft: ["auto", "7.5px", "7.5px"],
            marginRight: ["7.5px", "auto", "auto"],
            cursor: "pointer",
            color: ["#280000", "#280000", (searchOpen ? "#280000" : "white")],
            ':hover': {
              color: "#FC164B"
            },
            ':active': {
              color: "#ed587b"
            }
          }}>
            <FontAwesomeIcon icon={faMagnifyingGlass} sx={{ height: "20px", width: "20px", color: "inherit" }} />
          </button>
          <button
            type="button"
            onClick={() => { if (searchOpen) { setSearchOpen(false) } }}
            sx={{
              display: ["none", "none", (searchOpen ? "inline-block" : "none")],
              appearance: "none",
              background: "none",
              border: "none",
              height: "20px",
              width: "20px",
              margin: "auto",
              padding: 0,
              cursor: "pointer",
              color: "#280000",
              ':hover': {
                color: "#FC164B"
              },
              ':active': {
                color: "#ed587b"
              }
            }}>
            <FontAwesomeIcon icon={faCircleXmark} sx={{ height: "20px", width: "20px", color: "inherit" }} />
          </button>
        </Box>
      </form>
    </div>
  )
}

export const searchFields = {
  SEARCH_TERM: yup
    .string()
    .label("Search term")
    .required()
    .min(2)
    .max(50)
}