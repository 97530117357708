import * as React from 'react'
import i18n from "i18next"
import Backend from "i18next-xhr-backend"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next, useTranslation } from 'react-i18next';
import { createContext, useContext, useMemo } from 'react';
import { isRtlLang } from 'rtl-detect';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}.json',
    },

    nsSeparator: false,
    keySeparator: false,
    fallbackLng: "en",

    interpolation: {
      escapeValue: false,
    },

    react: {
      wait: true,
      useSuspense: false
    },
  })

export default i18n

type direction = 'left' | 'right'

export const TextDirectionContext = createContext<{
  isRtl: boolean
  locale?: any
}>({
  isRtl: false
})

const capitalise = (s: string) => {
  const [A, ...xyz] = s.split('')
  return A.toUpperCase() + xyz.join('')
}

export const useTextDirection = (locale?: string) => {
  const localeContext = useContext(TextDirectionContext)
  const isRtl = useMemo(() => locale ? !!isRtlLang(locale) : localeContext?.isRtl, [locale, localeContext])

  return useMemo(() => {
    const right: direction = !isRtl ? 'right' : 'left'
    const left: direction = !isRtl ? 'left' : 'right'
    const defaultDir = left
    const oppositeDir = right

    return {
      debug: {
        localeContext,
        locale,
      },
      isRtl,
      right,
      left,
      defaultDir,
      oppositeDir,
      marginLeft: `margin${capitalise(defaultDir)}` as 'marginLeft' | 'marginRight',
      marginRight: `margin${capitalise(oppositeDir)}` as 'marginRight' | 'marginLeft',
      flexRow: !isRtl ? `row` : `row-reverse` as 'row-reverse' | 'row',
      flexRowReverse: !isRtl ? `row-reverse` : `row` as 'row-reverse' | 'row'
    }
  }, [isRtl])
}

export const TextDirectionProvider: React.FC<{ locale?: string }> = ({ locale, children }) => {
  const { i18n: { language } } = useTranslation()
  const isRtl = useMemo(() => !!isRtlLang(locale || language), [locale, language])
  return (
    <TextDirectionContext.Provider value={{ isRtl, locale }}>
      {children}
    </TextDirectionContext.Provider>
  )
}