import React from "react"
import { createContext, useContext } from "react"
import MatomoTracker from "@datapunt/matomo-tracker-js"
import {
  TrackEventParams,
  TrackLinkParams,
  TrackPageViewParams,
} from "@datapunt/matomo-tracker-js/lib/types"
import i18n from "../i18n"
import { mergeWith } from "lodash"

export interface AnalyticsData {
  /**
   * Category of the event
   */
  category: string
  /**
   * Human readable name
   */
  label?: string
  [key: string]: string | number | boolean
}

const TRACKING_ENABLED = process.env.NODE_ENV === "production"
const LOGGING_ENABLED = process.env.NODE_ENV !== "production"

let matomo: MatomoTracker = {} as any

if (typeof window !== "undefined" && typeof document !== "undefined") {
  matomo = new MatomoTracker({
    urlBase: process.env.GATSBY_MATOMO_SITE_URL!,
    siteId: parseInt(process.env.GATSBY_MATOMO_SITE_ID!),
  })
  window._paq.push(["requireConsent"])

  document.addEventListener("cookieyes_consent_update", function(eventData) {
    const data = eventData.detail
    if (data?.accepted?.includes("analytics")) {
      console.log("giving consent...")
      window._paq.push(["rememberConsentGiven"])
      console.log("done giving consent.")
    } else if (data?.rejected?.includes("analytics")) {
      console.log("forgetting consent...")
      window._paq.push(["forgetConsentGiven"])
      console.log("done forgetting consent.")
    }
  })

  // window.Metomic?.('getConsentState', { slug: 'user-analytics' }, (p) => {
  //   const { enabled } = p
  //   if (enabled) {
  //     window._paq.push(['rememberConsentGiven'])
  //   } else {
  //     window._paq.push(['forgetConsentGiven']);
  //     window.Metomic?.('ConsentManager:show')
  //   }
  // })

  matomo.trackEvents()

  // window.Metomic?.('ConsentManager:onConsentStateChange', (p) => {
  //   const { slug, state } = p
  //   // slug = the micropolicy slug for which consent has changed
  //   // state = CONSENTED | DECLINED
  //   if (slug === 'user-analytics' && state === 'CONSENTED') {
  //     window._paq.push(['rememberConsentGiven'])
  //   }
  // })
}

export const analytics = {
  pageView: (args: CustomMatomoConfig<TrackPageViewParams>) => {
    if (!TRACKING_ENABLED) return
    try {
      const finalArgs: typeof args = dedupeCustomDimensions(
        mergeWith(baseArgs(), args, mergeFn)
      )
      LOGGING_ENABLED && console.log("pageView tracked", finalArgs)
      matomo.trackPageView(finalArgs)
    } catch (e) {
      console.error("Couldn't log pageview")
    }
  },
  trackLink: (args: CustomMatomoConfig<TrackLinkParams>) => {
    if (!TRACKING_ENABLED) return
    try {
      const finalArgs: typeof args = dedupeCustomDimensions(
        mergeWith(baseArgs(), args, mergeFn)
      )
      LOGGING_ENABLED && console.log("link tracked", finalArgs)
      matomo.trackLink(finalArgs)
    } catch (e) {
      console.error("Couldn't log events.")
    }
  },
  trackEvent: (args: CustomMatomoConfig<TrackEventParams>) => {
    if (!TRACKING_ENABLED) return
    try {
      const finalArgs: typeof args = dedupeCustomDimensions(
        mergeWith(baseArgs(), args, mergeFn)
      )
      LOGGING_ENABLED && console.log("event tracked", finalArgs)
      matomo.trackEvent(finalArgs)
    } catch (e) {
      console.error("Couldn't log events.")
    }
  },
}

type CustomDimension =
  | "method"
  | "option"
  | "preferredLocale"
  | "currentLocale"
  | "environment"

type CustomMatomoConfig<T> = Omit<T, "customDimensions"> & {
  customDimensions?: Array<{ id: CustomDimension; value: any }>
}

const baseArgs = (): CustomMatomoConfig<TrackPageViewParams> => ({
  customDimensions: [
    { id: "environment", value: process.env.NODE_ENV },
    { id: "currentLocale", value: i18n.language },
  ],
})

const mergeFn = (objValue: unknown, srcValue: unknown) => {
  if (Array.isArray(objValue)) {
    return objValue.concat(srcValue)
  }
}

const dedupeCustomDimensions = <
  T extends CustomMatomoConfig<TrackPageViewParams>
>(
  args: T
): T => {
  let a = args
  let dict: { [key: string]: any } = {}
  a.customDimensions?.forEach(c => {
    if (!c.value) return
    dict[c.id as CustomDimension] = c.value
  })
  a.customDimensions = Object.entries(dict).map(
    ([key, value]) => ({ [key]: value } as any)
  )
  return a
}

export type Analytics = typeof analytics

export const AnalyticsContext = createContext(analytics)

export const useAnalytics = () => useContext(AnalyticsContext)

export const AnalyticsProvider = (props: any = {}) => (
  <AnalyticsContext.Provider value={analytics} {...props} />
)
