/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */


import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useTranslation } from 'react-i18next';
import { isRtlLang } from "rtl-detect";

function SEO({ locales = [], path, description, lang = 'en', meta = [], title, imageUrl, websiteTitle, authorTwitterHandle, twitterHandle }: {
  path?: string
  websiteTitle?: string
  authorTwitterHandle?: string
  twitterHandle?: string
  imageUrl?: string
  description?: string
  lang?: string
  meta?: any[]
  title?: string
  locales?: { path: string, locale: string }[]
}) {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetadata {
        site: siteConfigJson(id: { eq: "Social share info"}) {
          siteUrl
          title
          description
          twitter
          shareImage {
            publicURL
          }
        }
      }
    `
  )

  const { t, i18n: { language } } = useTranslation()

  const _websiteTitle = t(websiteTitle || site.title)
  const _twitterHandle = twitterHandle || site.twitter
  const _authorTwitterHandle = authorTwitterHandle || site.twitter
  const _title = t(title || site.title)
  const _description = t(description || site.description)
  const _imageUrl = new URL(imageUrl || site.shareImage?.publicURL, site.siteUrl).toString()
  const _url = new URL(path || site.siteUrl, site.siteUrl).toString()

  return (
    <Helmet
      htmlAttributes={{
        dir: isRtlLang(language) ? 'rtl' : undefined,
        lang,
      }}
      title={_title}
      titleTemplate={_title === site.title ? _title : `%s | ${site.title}`}
      meta={[
        {
          name: "description",
          content: _description
        },
        {
          property: "og:title",
          content: _title
        },
        {
          property: "og:description",
          content: _description
        },
        {
          property: "og:image",
          content: _imageUrl
        },
        {
          property: "og:url",
          content: _url
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:site_name",
          content: _websiteTitle
        },
        {
          property: "og:locale",
          content: lang
        },
        {
          name: "twitter:card",
          content: "summary_large_image"
        },
        {
          name: "twitter:site",
          content: _twitterHandle
        },
        {
          name: "twitter:author",
          content: _authorTwitterHandle
        },
        {
          name: "twitter:title",
          content: _title
        },
        {
          name: "twitter:description",
          content: _description
        },
        {
          name: "twitter:image",
          content: _imageUrl
        },
      ].concat(meta)}
      link={
        locales.map(l => ({
          rel: "alternate",
          href: site.siteUrl + '/' + l.path,
          hreflang: l.locale
        }))
      }
    />
  )
}

export default SEO
