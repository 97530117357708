import React from "react"
import { SvgGraphic } from "../components/image"

export const CaretDown: SvgGraphic = props => (
  <SvgGraphic viewBox="0 0 12 6" {...props}>
    <path d="M6 6L0.803848 0L11.1962 0L6 6Z" fill="currentColor" />
  </SvgGraphic>
)

export const CaretUp: SvgGraphic = props => (
  <SvgGraphic viewBox="0 0 12 6" {...props}>
    <path d="M6 0L11.1962 6H0.803847L6 0Z" fill="currentColor" />
  </SvgGraphic>
)