import React, { useRef, useEffect, useState } from "react"
import Helmet from "react-helmet"
import qs from "query-string"

export function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef()

  // Store current value in ref
  useEffect(() => {
    ref.current = value
  }, [value]) // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current
}

export function PrintStyleSheet() {
  return (
    <Helmet
      style={[
        {
          cssText: `      
            @page {
              margin-top: 75px;
              margin-bottom: 50px;
            }

            @page:first {
              margin-top: 50px;
              margin-bottom: 50px;
            }

            @media print {
              #contentColumns div:nth-child(2) {
                margin: 0 auto;
              }

              #social-sharer, #article-language-selector, #site-header-items, #footer-margin, #popup-trigger, #recommended-footer, a[href="#script-holder"], a[href="/fundraiser?"], .page-footer-links, #briefing-footer, #pi-page-content > div:last-child, #pi-page-content > div:nth-last-child(2) {
                display: none;
              }
            }
          `,
        },
      ]}
    />
  )
}

export const isPosStrInt = (value: string) => {
  var n = Math.floor(Number(value))
  return n !== Infinity && String(n) === value && n > 0
}

export const updateQueryString = (key: string, value: string) => {
  if (window) {
    const url = new URL(window.location.href)
    url.searchParams.set(key, value)
    window.history.pushState(null, "", url.toString())
  }
}
