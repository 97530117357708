import { ContentNode, Edge } from "./content"

const pillarColors = {
  movement: "movementHex",
  wire: "wireHex",
  blueprint: "blueprintHex",
  observatory: "observatoryHex",
}

export function colourMidpoint(hexValue1: string, hexValue2: string) {
  try {
    if (hexValue1.includes("#")) {
      hexValue1 = hexValue1.substring(1)
    }

    if (hexValue2.includes("#")) {
      hexValue2 = hexValue2.substring(1)
    }

    if (
      hexValue1.includes("#") ||
      hexValue1.length != 6 ||
      hexValue2.includes("#") ||
      hexValue2.length != 6
    ) {
      return "#000000"
    }

    let hexArray1: any[] = []
    while (hexValue1.length > 0) {
      hexArray1.push(hexValue1.substring(0, 2))
      hexValue1 = hexValue1.substring(2)
    }

    let hexArray2: any[] = []
    while (hexValue2.length > 0) {
      hexArray2.push(hexValue2.substring(0, 2))
      hexValue2 = hexValue2.substring(2)
    }

    for (let i = 0; i < hexArray1.length; i++) {
      hexArray1[i] = parseInt(hexArray1[i], 16)
    }

    for (let i = 0; i < hexArray2.length; i++) {
      hexArray2[i] = parseInt(hexArray2[i], 16)
    }

    let finalHexValue = "#"
    for (let i = 0; i < hexArray1.length; i++) {
      finalHexValue =
        finalHexValue +
        Math.round((hexArray1[i] + hexArray2[i]) / 2).toString(16)
    }

    const reg = /^#[0-9A-F]{6}$/i

    if (reg.test(finalHexValue)) {
      return finalHexValue
    } else {
      return "#000000"
    }
  } catch (e) {
    return "#000000"
  }
}

export function getHexType(type: string) {
  if (type == "wire-articles") {
    return pillarColors.wire
  } else if (
    type == "movement-campaigns" ||
    type == "movement-actions" ||
    type == "movement-articles"
  ) {
    return pillarColors.movement
  } else if (type == "blueprint-documents") {
    return pillarColors.blueprint
  } else if (type == "observatory-articles") {
    return pillarColors.observatory
  } else {
    return "#280000"
  }
}

export function includeRelevant(
  searchTerm: string,
  searchString: string,
  currLocale: boolean = false
) {
  let relevance = 0

  if (searchString && searchTerm && searchString.includes(searchTerm)) {
    const searchStringWords = searchString.split(" ")

    searchStringWords.forEach(w => {
      if (
        w.includes(searchTerm) &&
        searchTerm.length >= Math.round(w.length / 1.4)
      ) {
        const relevanceMultiplier =
          Math.round((searchTerm.length / w.length) * 100) / 100
        relevance = relevance + Math.round(10 * relevanceMultiplier)
      }
    })
  }

  if (currLocale) {
    return Math.round(relevance * 1.75)
  } else {
    return relevance
  }
}

export function includeRelevantNode(
  searchTerms: string[],
  node: ContentNode,
  language: string = "en"
) {
  let relevance = 0
  let relevantTerms: string[] = []

  searchTerms.forEach(searchTerm => {
    const preRelevance = relevance

    let translationRelevance = 0
    let relevantTranslations = 0

    node.fields.translations?.forEach(t => {
      const currLocale = language == t.childMarkdownRemark?.frontmatter?.locale
      const preTranslationRevelance = translationRelevance

      translationRelevance =
        translationRelevance +
        includeRelevant(
          searchTerm,
          t.childMarkdownRemark?.frontmatter?.title?.toLowerCase(),
          currLocale
        )
      translationRelevance =
        translationRelevance +
        includeRelevant(
          searchTerm,
          t.childMarkdownRemark?.frontmatter?.shortAbstract?.toLowerCase(),
          currLocale
        )
      translationRelevance =
        translationRelevance +
        includeRelevant(
          searchTerm,
          t.childMarkdownRemark?.frontmatter?.abstract?.toLowerCase(),
          currLocale
        )

      if (translationRelevance > preTranslationRevelance) {
        relevantTranslations = relevantTranslations + 1
      }
    })

    if (translationRelevance > 0 && relevantTranslations > 0) {
      translationRelevance = Math.round(
        translationRelevance / relevantTranslations
      )

      relevance = relevance + translationRelevance
    }

    node.frontmatter?.region?.fields?.translations?.forEach(t => {
      const currLocale = language == t.childMarkdownRemark?.frontmatter?.locale
      relevance =
        relevance +
        includeRelevant(
          searchTerm,
          t.childMarkdownRemark?.frontmatter?.title?.toLowerCase(),
          currLocale
        )
    })

    node.frontmatter?.country?.fields?.translations?.forEach(t => {
      const currLocale = language == t.childMarkdownRemark?.frontmatter?.locale
      relevance =
        relevance +
        includeRelevant(
          searchTerm,
          t.childMarkdownRemark?.frontmatter?.title?.toLowerCase(),
          currLocale
        )
    })

    node.frontmatter?.topic?.fields?.translations?.forEach(t => {
      const currLocale = language == t.childMarkdownRemark?.frontmatter?.locale
      relevance =
        relevance +
        includeRelevant(
          searchTerm,
          t.childMarkdownRemark?.frontmatter?.title?.toLowerCase(),
          currLocale
        )
    })

    if (relevance > preRelevance && !relevantTerms.includes(searchTerm)) {
      relevantTerms.push(searchTerm)
    }
  })
  if (relevantTerms.length > 0) {
    return (
      Math.round(relevance * (relevantTerms.length / searchTerms.length)) *
      +(relevantTerms.length >= searchTerms.length / 2)
    )
  } else {
    return 0
  }
}

export function showDonationBanner(type: string, siteSettingsData: any) {
  try {
    const SiteSettings = siteSettingsData.allSiteSettingsJson.edges[0].node

    if (type == "wire-articles") {
      return SiteSettings?.showWireDonationBoxes
    } else if (type == "movement-campaigns" || type == "movement-articles") {
      return SiteSettings?.showMovementDonationBoxes
    } else if (type == "blueprint-documents") {
      return SiteSettings?.showBlueprintDonationBoxes
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}

export function groupByMultipleTopics(collection: Edge<ContentNode>[]) {
  let resDict: { [id: string]: Edge<ContentNode>[] } = {}
  let topicDict: { [id: string]: ContentNode } = {}

  collection.forEach(article => {
    if (article.node.frontmatter.topic?.id in resDict) {
      resDict[article.node.frontmatter.topic?.id].push(article)
    } else {
      resDict[article.node.frontmatter.topic?.id] = [article]
      topicDict[article.node.frontmatter.topic?.id] =
        article.node.frontmatter.topic
    }

    if (article.node.frontmatter.secondaryTopics) {
      article.node.frontmatter.secondaryTopics.forEach(secondaryTopic => {
        if (secondaryTopic.id in resDict) {
          resDict[secondaryTopic.id].push(article)
        } else {
          resDict[secondaryTopic.id] = [article]
          topicDict[secondaryTopic.id] = secondaryTopic
        }
      })
    }
  })

  return { resDict, topicDict }
}

export function ruLinebreakRegex() {
  const articleContent = document.getElementsByClassName("article-content")

  const relevantNodes: Element[] = []

  for (var i = 0; i < articleContent.length; i++) {
    const walker = document.createTreeWalker(articleContent[i], 1)

    while (walker.nextNode()) {
      relevantNodes.push(walker.currentNode)
    }
  }

  const uniqueRelevantNodes = [
    ...new Set(
      relevantNodes.filter(
        node => node.childNodes.length == 1 && node.childNodes[0].nodeType == 3
      )
    ),
  ]

  for (var i = 0; i < uniqueRelevantNodes.length; i++) {
    uniqueRelevantNodes[i].innerHTML = uniqueRelevantNodes[i].innerHTML.replace(
      /(\s)(Не|не|В|в|С|с|К|к)\s/,
      "$&\x01\x02&nbsp;"
    )
    uniqueRelevantNodes[i].innerHTML = uniqueRelevantNodes[i].innerHTML.replace(
      /\s(—\s)/,
      "$&&nbsp;\x01"
    )
  }
}
