/** @jsx jsx */

import { Link as GatsbyLink } from "gatsby"
import { jsx, Heading } from 'theme-ui';
import { ViewElement, Rows } from "./atoms"
import { ContentNode } from "../data/content"
import { ComponentType, MouseEvent } from "react"
import { useAnalytics, Analytics } from '../data/analytics';
import { useTextDirection } from '../i18n';

export type LinkProps = {
  to: string | ContentNode
  variant?: string
  icon?: boolean
  arrow?: boolean
  linkType?: 'download' | 'link'
  onClick?: (event: MouseEvent, analytics: Analytics) => any
}

export const Link: ViewElement<LinkProps, React.AnchorHTMLAttributes<{}>> = ({
  variant: linkVariant = "default",
  children,
  icon,
  arrow,
  to,
  linkType = 'link',
  onClick,
  ...props
}) => {
  let href = typeof to === "string" || to === null ? to : to.fields.path
  href = href === null ? ' ' : href
  const variant = "link." + linkVariant
  const analytics = useAnalytics()

  if (["http", "mailto"].some(s => href.startsWith(s))) {
    return (
      <a
        sx={{ variant, userSelect: "none" }}
        {...props}
        target="_blank"
        href={href}
        onClick={onClick ? e => onClick(e, analytics) : (e) => analytics.trackLink({ href, linkType })}
      >
        {children}
        {href.startsWith("http") && !icon && arrow && <span>{"↗"}</span>}
      </a>
    )
  } else {
    return (
      <GatsbyLink
        {...(props as any)}
        sx={{ variant, userSelect: "none" }}
        activeClassName="active"
        to={href}
        onClick={onClick ? e => onClick(e, analytics) : (e) => analytics.trackLink({ href, linkType })}
      >
        {children}
      </GatsbyLink>
    )
  }
}

export const BlockLink: ViewElement<LinkProps> = props => (
  <Link sx={{ display: "block" }} {...props} />
)

export const LiteralLink: ViewElement<LinkProps & { to: string }> = props => (
  <Link {...props}>{props.to.replace(/^.*:\/\//, "")} ↗</Link>
)

export const MajorLink: ViewElement<LinkProps & {
  title: string
  color: string
  icon: ComponentType
}> = ({ title, icon: Logo, color, children, ...props }) => {
  const dir = useTextDirection()

  return (
    <BlockLink
      {...props}
      sx={{
        px: [3, 4], mx: [-3, -4], py: [2, 4], borderRadius: 20,
        display: "flex",
        flexDirection: ["row", null, "column"],
        color,
        justifyContent: ["flex-start", null, "center"],
        textAlign: dir.left
      }}
    >
      <Rows gap={2} sx={{ width: '100%' }}>
        <Heading variant="headings.display" sx={{ display: 'flex', fontSize: [null, null, "32px"], flexDirection: [dir.flexRow, null, 'column'], justifyContent: 'flex-start' }}>
          <Logo
            sx={{
              display: ["inline-block", null, "block"],
              width: [32, 32, 152],
              height: [32, 32, 152],
              mr: [2, null, null],
              [dir.marginRight]: [2, null, 'auto'],
              mb: [null, null, 2],
              verticalAlign: "bottom",
            }}
          />
          {dir.isRtl && '←'} {title} {!dir.isRtl && '→'}
        </Heading>
        <Heading
          variant='headings.3'
          sx={{ color: "black" }}
        >
          {children}
        </Heading>
      </Rows>
    </BlockLink>
  )
}
