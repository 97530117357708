/** @jsx jsx */

import { useEffect, useState } from "react"
import { Trans } from "react-i18next"
import { Box, Button, Grid, Heading, jsx } from "theme-ui"
import { ActionNetworkFooterForm } from "./an-footer-form"
import { Rows, ViewElement } from "./atoms"
import theme from "../gatsby-plugin-theme-ui/index"
import { ContentBlock } from "./page"
import { Localised, TertiaryFeatured } from "./featured"
import { Link } from "./nav"
import { faChevronUp, faXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const BriefingDynamicPopup: ViewElement = () => {
  const [open, setOpen] = useState(false)

  const [sticky, setSticky] = useState(true)

  const overallHeight = 500
  const closedHeight = 0

  useEffect(() => {
    const stickyElement = document.getElementById("briefing-footer")
    let observer: IntersectionObserver | undefined = undefined

    if (stickyElement) {
      observer = new IntersectionObserver(
        ([e]) => {
          const isSticky = !!(e.intersectionRatio < 1)

          setSticky(isSticky)
        },
        { threshold: [1] }
      )

      observer.observe(stickyElement)
    }

    return () => {
      if (observer && stickyElement) {
        observer.unobserve(stickyElement)
      }
    }
  }, [])

  return (
    <Box
      sx={{
        transition: "max-height 0.2s",
        backgroundColor: "#FC164B",
        position: "absolute",
        bottom: 0,
        width: "100%",
        zIndex: 10,
        maxHeight: open || !sticky ? overallHeight + "px" : closedHeight + "px",
        minHeight: closedHeight + "px",
      }}
    >
      {sticky && (
        <Box
          onClick={() => setOpen(!open)}
          sx={{
            transition: `color 0.3s, max-width ${
              open ? "1" : "2"
            }s, top 0.1s, right 0.1s`,
            cursor: sticky ? "pointer" : "default",
            lineHeight: "40px",
            fontSize: "20px",
            padding: 0,
            paddingLeft: !open ? "15px" : 0,
            paddingRight: !open ? "40px" : 0,
            backgroundColor: "#FC164B",
            borderRadius: "50px",
            color: open ? "red" : "#280000",
            position: "absolute",
            top: open ? "-45px" : ["-45px", "-55px", "-65px"],
            right: open ? "5px" : ["5px", "15px", "25px"],
            height: "40px",
            minWidth: "40px",
            maxWidth: open ? "40px" : "400px",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {!open && <Trans>Receive the PI Briefing</Trans>}
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              height: "40px",
              width: "40px",
              textAlign: "center",
              fontSize: "25px",
              lineHeight: "25px",
              transition: "transform 0.5s",
              transform: open || !sticky ? "scale(1, -1)" : "scale(1, 1)",
              color: "#280000",
              padding: "7.5px",
              backgroundColor: "#FC164B",
            }}
          >
            {(open || !sticky) && <FontAwesomeIcon icon={faXmark} />}
            {!(open || !sticky) && <FontAwesomeIcon icon={faChevronUp} />}
          </Box>
        </Box>
      )}
      <Box sx={{ padding: "25px", position: "relative" }}>
        <Grid columns={[1, 2, 2]}>
          <div sx={{ variant: "headings.2", maxWidth: "20ch" }}>
            <Trans>Receive the Progressive International briefing</Trans>
          </div>
          <ActionNetworkFooterForm popup={false} />
        </Grid>
      </Box>
    </Box>
  )
}

export const RecommendedDynamicPopup: React.FC<{ topic; related }> = ({
  topic,
  related,
}) => {
  const [open, setOpen] = useState(false)
  const [sticky, setSticky] = useState(true)

  const closedHeight = 0

  useEffect(() => {
    const stickyElement = document.getElementById("popup-trigger")
    let observer: IntersectionObserver | undefined = undefined

    if (stickyElement) {
      observer = new IntersectionObserver(
        ([e]) => {
          const isSticky = !!(e.intersectionRatio <= 0)

          setSticky(isSticky)

          if (open) {
            setOpen(false)
          }
        },
        { threshold: [0] }
      )

      observer.observe(stickyElement)
    }

    return () => {
      if (observer && stickyElement) {
        observer.unobserve(stickyElement)
      }
    }
  }, [])

  return (
    <div
      id="recommended-footer"
      sx={{
        position: sticky ? "sticky" : "relative",
        bottom: "-1px",
        zIndex: "40",
      }}
    >
      <ContentBlock sx={{ position: "relative", padding: 0, maxWidth: "none" }}>
        <div
          sx={{
            position: "absolute",
            bottom: 0,
            zIndex: 5,
            width: "100%",
            maxHeight: open || !sticky ? "75vh" : closedHeight + "px",
            minHeight: closedHeight + "px",
            transition: "max-height 0.5s",
            //transition: `${!(sticky || (!sticky && open)) ? "max-height 0.5s, " : ""}height 0.5s`,
            boxShadow: "0px -5px 10px rgba(40, 0, 0, 0.05)",
            backgroundImage: `linear-gradient(white, ${theme.colors.background})`,
            "& ~ .page-footer-links": {
              bg: "white",
            },
          }}
        >
          {sticky && (
            <Box
              onClick={() => setOpen(!open)}
              sx={{
                transition: `color 0.3s, max-width ${
                  open ? "1" : "2"
                }s, top 0.1s, right 0.1s`,
                cursor: sticky ? "pointer" : "default",
                lineHeight: "40px",
                fontSize: "20px",
                padding: 0,
                paddingLeft: !open ? "15px" : 0,
                paddingRight: !open ? "40px" : 0,
                backgroundColor: "#280000",
                borderRadius: "50px",
                color: open ? "#280000" : "white",
                position: "absolute",
                top: open ? "-45px" : ["-45px", "-55px", "-65px"],
                right: open ? "5px" : ["5px", "15px", "25px"],
                height: "40px",
                minWidth: "40px",
                maxWidth: open ? "40px" : "400px",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {!open && (
                <div>
                  <Trans>More in</Trans>{" "}
                  <Localised node={topic}>{n => n?.title}</Localised>
                </div>
              )}
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  height: "40px",
                  width: "40px",
                  textAlign: "center",
                  fontSize: "25px",
                  lineHeight: "25px",
                  transition: "transform 0.5s",
                  transform: open || !sticky ? "scale(1, -1)" : "scale(1, 1)",
                  color: "white",
                  padding: "7.5px",
                  backgroundColor: "#280000",
                }}
              >
                {(open || !sticky) && <FontAwesomeIcon icon={faXmark} />}
                {!(open || !sticky) && <FontAwesomeIcon icon={faChevronUp} />}
              </Box>
            </Box>
          )}
          <ContentBlock>
            <Rows gap={3}>
              <Heading
                variant="headings.3"
                sx={{ minHeight: closedHeight - 25 + "px" }}
              >
                <span sx={{ color: "grey" }}>
                  <Trans>More in</Trans>
                </span>{" "}
                <Localised node={topic}>{n => n?.title}</Localised>
              </Heading>

              <Grid
                columns={[1, 3]}
                sx={{
                  overflowY: ["scroll", "unset", "unset"],
                  maxHeight: "50vh",
                }}
              >
                {related
                  .sort((a, b) => {
                    if (!a || !a.frontmatter || !a.frontmatter.publishDate) {
                      return 1
                    } else if (
                      !b ||
                      !b.frontmatter ||
                      !b.frontmatter.publishDate
                    ) {
                      return -1
                    } else {
                      return a.frontmatter.publishDate >
                        b.frontmatter.publishDate
                        ? -1
                        : 1
                    }
                  })
                  .filter((_, i) => i < 3)
                  .map(rel => (
                    <Link key={rel.id} to={rel.fields.path}>
                      <TertiaryFeatured
                        key={rel.id}
                        node={rel}
                        truncated={true}
                      />
                    </Link>
                  ))}
              </Grid>
            </Rows>
          </ContentBlock>
        </div>
      </ContentBlock>
    </div>
  )
}
