import originalLanguages, { getLanguageName } from "../data/languages"
import { useTranslation } from "react-i18next"
import { useStaticQuery, graphql } from "gatsby"
import { useAnalytics } from "../data/analytics"

export const useLanguageSelector = () => {
  const { i18n } = useTranslation()

  const { languages } = useStaticQuery(graphql`
    query LocalesQuery {
      languages: allAvailableLocale
    }
  `) as { languages: string[] }

  const analytics = useAnalytics()

  const changeLanguage = (l: string) => {
    analytics.trackEvent({
      category: "internationalisation",
      action: "select-language",
      customDimensions: [
        { id: "method", value: "navbar-language-selector" },
        { id: "option", value: l },
        { id: "preferredLocale", value: l },
      ],
    })
    i18n.changeLanguage(l)
  }

  const code = i18n?.language?.toLowerCase()

  // create final list of available languages by filtering entire languages
  // original list by the available locales taken from query;
  // then, create final list of objects containing `code`, taken from `isoName`
  // and `name`, taken from `nativeName`
  const availableLanguages = originalLanguages
    .filter(originalLanguage => languages.find(language => language === originalLanguage.isoName))
    .sort((languageA, languageB) => (languageA.isoName > languageB.isoName ? 1 : -1))
    .map(availableLanguage => ({
      code: availableLanguage.isoName,
      name: availableLanguage.nativeName,
    }))

  return {
    currentLanguageCode: { code, name: getLanguageName(code as any) },
    languages: availableLanguages,
    changeLanguage,
  }
}
