/** @jsx jsx */

import { useStaticQuery, graphql } from 'gatsby';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { isPossiblePhoneNumber } from 'libphonenumber-js';
import countries from '../../data/countries';

export function isNullOrWhitespace(input_val: string) {
  if (typeof input_val === 'undefined' || input_val == null) return true;

  return input_val.trim().length < 1;
}

export const interestCodes = {
  // Type
  "Individual": "9489eff55a",
  "Organisation": "00f09bfcf6",
  // Generic signup
  "Hopes": "58389d396c",
  // Individual groups
  "IndividualContributions": "c854769129",
  // Organisation groups
  "OrgType": "464876f945",
  "OrgSize": "08c5ed6f64",
  "OrgContributions": "c93e996d8f",
}

export type MailchimpInterest = {
  id: string
  name: string
  category_id: string
}

const INTEREST_PREFIX = 'ii_'
export const isMailchimpInterestId = (id: string) => id.includes(INTEREST_PREFIX)
export const prefixMailchimpInterestId = (id: string) => INTEREST_PREFIX + id
export const getMailchimpInterestId = (id: string) => id.replace(INTEREST_PREFIX, '')

export const NO_DONATION = "NO_DONATION"

export const donationFields = {
  INTERVAL: yup
    .mixed()
    .oneOf(['one-off', 'month', 'year', NO_DONATION])
    .label('Contribution frequency')
    .required(),
  AMOUNT: yup
    .string()
    .when('INTERVAL', {
      is: NO_DONATION,
      then: yup.string()
        .nullable(),
      otherwise: yup.string()
        .required()
        .test('is-valid-number', 'Donation amount must be a number', v => !!parseInt(v))
        .test('is-big-number', 'Donation amount must be at least $1', v => parseFloat(v) >= 1)
        .test('is-small-number', 'Donation amount must be less than $999,999', v => parseFloat(v) < 999999)
    })
    .label('Contribution amount'),
  SUBSCRIBE: yup
    .boolean()
    .label('Subscribe to PI briefing')
}

export const mailchimpFields = {
  b_7d61a70102ab811e6282bee60_b87cfcec60: yup
    .string()
    .label('Anti-spam field'),
}

export const joinFields = {
  AGREE_TO_PRINCIPLES: yup
    .boolean()
    .oneOf([true], "You must agree to our principles to join")
    .required("You must agree to our principles to join")
}

export const volunteeringFields = {
  community_organising: yup
    .boolean()
    .label("Community organising"),
  fundraising: yup
    .boolean()
    .label("Fundraising"),
  translations: yup
    .boolean()
    .label("Translations"),
  art_illustration: yup
    .boolean()
    .label("Art & illustration"),
  video_editing: yup
    .boolean()
    .label("Video editing"),
  web_development: yup
    .boolean()
    .label("Web development"),
  photography: yup
    .boolean()
    .label("Photography")
}

export const interestTagFields = {
  INTEREST_TAGS: yup
    .array()
    .of(yup.boolean())
}

export const emailFields = {
  EMAIL: yup
    .string()
    .label("Email")
    .required()
    .email()
}

export const askDonateFields = {
  ask_donate: yup
    .boolean()
    .label("Would you like to donate")
}

export const personalFields = {
  FNAME: yup
    .string()
    .label('First name')
    .required()
    .min(2)
    .max(150),
  LNAME: yup
    .string()
    .label('Last name')
    .test("length-or-empty", "Last name cannot be less than 2 characters.", (val) => {
      if (val == null) {
        return false;
      }
      return (val.length == 0 || (val.length >= 2 && val.length <= 150));
    }),
}

export const countryFields = {
  COUNTRY: yup
    .string()
    .label('Country')
    .oneOf(Object.keys(countries), "Select a country")
    .required("Select a country"), //"What country do you associate your activism with?"),
}

export const langFields = (options: string[]) => {
  return {
    LANGUAGE: yup
      .string()
      .label('Preferred language')
      .oneOf(options, "Please select a language")
      .required()
  }
}

export const localeFields = {
  LANGUAGE: yup
    .string()
    .label('Preferred language')
    .required(),
  ...countryFields,
  CITY: yup
    .string()
    .label('Town or city')
    .required() //"What town or city do you spend most of your time in?")
    .min(3)
    .max(150),
}

export const surveyFields = {
  MOTIVATION: yup
    .string()
    .label('Your motivation')
    .max(4000),
}

export const organisationFields = {
  ORG_NAME: yup
    .string()
    .label("Your organisation's name")
    .required()
    .min(3)
    .max(200),
  ORG_DESC: yup
    .string()
    .label("Organisation description")
    .required()
    .min(5)
    .max(400),
  ORG_SIZE: yup
    .string()
    .label("Membership")
    .required(),
  ORG_URL: yup
    .string()
    .label("Organisation website")
    .required()
    .url(),
  ORG_ROLE: yup
    .string()
    .label("Your organisational role")
    .required()
    .min(3)
    .max(200),
}

export const candleJarContactFields = {
  candle_jar_contact: yup
    .string()
    .label("Anti-Spam")
}

export const phoneFields = {
  PHONE: yup
    .string()
    .label("Phone Number (with area code)")
    .test("possible-check", "This number is not possible. Make sure the area code is included.", (value) => (isPossiblePhoneNumber(value) || isNullOrWhitespace(value)))
}

export type PayOptions = {
  amounts: Array<{
    monthly: string;
    annually: string;
    default?: boolean;
    custom?: boolean;
  }>
  intervals: Array<{
    key: string;
    label: string;
    default?: boolean;
  }>
}

export default function JoinForm({ children }: {
  children: (args: {
    loading: true
    mailchimpInterests: undefined
  } | {
    loading: false,
    mailchimpInterests: MailchimpInterest[]
  }) => any
}) {
  // Get Mailchimp options
  // TODO: This should be acquired at build time, triggered on Mailchimp changes
  const [mailchimpInterests, setOptions] = useState<MailchimpInterest[]>()
  const getSignupForm = async () => {
    const res = await fetch('/.netlify/functions/getSignupOptions')
    setOptions(await res.json())
  }
  useEffect(() => {
    getSignupForm()
  }, [])

  // If things haven't loaded yet, then show a loading page
  if (!mailchimpInterests) {
    return children({ loading: true } as any)
  } else {
    return children({ loading: false, mailchimpInterests })
  }
}

export const usePayOptions = () => {
  // Step 0: Pull in all contributions options from PI's Stripe account
  // TODO: Drive this via the CMS
  const { payOptions } = useStaticQuery(
    graphql`
      query DonationOptions {
        payOptions: siteConfigJson(id: { eq: "Donation settings"}) {
          amounts {
            monthly
            annually
            default
            custom
          }
          intervals {
            key
            label
            default
          }
        }
      }
    `
  ) as { payOptions: PayOptions }
  payOptions.amounts = payOptions.amounts.map(a => ({
    ...a,
    monthly: String(a.monthly),
    annually: String(a.annually)
  }))
  return payOptions
}
