import { throttle } from "lodash"
import { useCallback, useEffect, useRef } from "react"

const useThrottle = (fn: (...args: any[]) => any, delay: number) => {
  const callbackRef = useRef(fn)
  useEffect(() => {
    callbackRef.current = fn
  })

  return useCallback(
    throttle((...args) => callbackRef.current(...args), delay),
    [delay]
  )
}

export default useThrottle
