/** @jsx jsx */
import { jsx, Box, Text } from 'theme-ui';
import { useTranslation, Trans } from 'react-i18next';

export const HoldingPage = () => {
  const { t } = useTranslation()

  return (
    <Box sx={{
      position: 'absolute', top: 0, left: 0, height: '100%', width: '100%',
      backgroundSize: "cover",
      backgroundPosition: 'center',
      backgroundRepeat: 'norepeat',
      border: "none",
      backgroundImage: `url(${require("../images/map-light.png")})`,
    }}
    >
      <Box sx={{
        position: 'absolute',
        top: 20,
        left: 20,
        display: 'flex',
        height: `3em`
      }}>
        <img
          src={require('../images/circle.svg')}
          sx={{ size: `3em`, flexShrink: 0, flexGrow: 0, cursor: 'pointer' }}
        />
        <Box sx={{
          ml: 2,
          bg: 'black',
          color: 'white',
          p: 2,
          width: '100%',
          flexGrow: 1,
          flexShrink: 1,
          minWidth: 'auto',
          lineHeight: '0.9em',
          fontSize: `1.5em`,
          display: 'flex',
          alignItems: 'center',
          maxWidth: 'max-content'
        }}>
          <Box sx={{ textTransform: 'uppercase', fontWeight: 600 }}>
            {t('Progressive International').split(' ').map(word => (
              <Box key={word}>
                {word}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Text sx={{
        position: 'absolute',
        top: '45%',
        width: '100%',
        textAlign: 'center',
        fontSize: [5, 6, 7, 8],
        fontWeight: 600,
        p: 3
      }}>
        <Trans>Launching May 2020</Trans>
      </Text>
    </Box>
  )
}