import * as React from "react"
import { ThemeProvider } from "theme-ui"
import { Global, css } from "@emotion/core"
import { Fragment } from "react"
import { memoize, times } from "lodash"

const fib = memoize((x: number): number => (x === 0 ? x : x + fib(x - 1)))

const red = "#FC164B"
const redTint = "#FED0DB"
const purpleTint = "#EACCFA"
const greenTint = "#CCF2E7"
const blueTint = "#D8D4FF"

const theme = {
  fonts: {
    body:
      "'neue-haas-grotesk-display', 'Neue Haas', 'Helvetica Neue', 'Helvetica', system-ui, sans-serif",
    prose:
      "'freight-text-pro', 'FreightText Pro', Times, 'Times New Roman', serif",
    heading:
      "'neue-haas-grotesk-display', 'Neue Haas', 'Helvetica Neue', 'Helvetica', system-ui, sans-serif",
    monospace: "'IBM Plex Mono', Menlo, monospace",
  },
  breakpoints: ["40em", "52em", "72em"],
  space: times(15, x => 2.5 * fib(x)),
  fontSizes: [8, 10, 14, 15, 18, 24, 32, 38, 54],
  lineHeights: {
    body: 1.45,
    heading: 1.125,
  },
  sizes: {
    container: 1300,
    content: 600,
  },
  colors: {
    text: "#111",
    black: "#280000",
    grey: "#D4CCCC",
    offWhite: "#F7F4F1",
    background: "#F7F4F1",
    //
    purpleTint,
    movementHex: "#c600e2",
    movementHexLight: purpleTint,
    //
    blueTint,
    blueprintHex: "#3f27ff",
    blueprintHexLight: blueTint,
    //
    greenTint,
    wireHex: "#00bf89",
    wireHexLight: greenTint,
    //
    observatoryHex: "#ff0045",
    observatoryHexLight: "#f3e5e0",
    //
    latestHex: "#280000",
    latestHexLight: "#280000",
    //
    red,
    redTint,
    accent: red,
    accentTint: redTint,
  },
  text: {
    abstract: {
      fontFamily: "heading",
      fontWeight: 400,
      fontSize: 24,
      lineHeight: "110%",
    },
    body: {
      fontFamily: "prose",
      fontSize: [16, null, 19],
      letterSpacing: -0.1,
      lineHeight: "130%",
      "&": {
        h1: { variant: "headings.3", fontFamily: "heading", lineHeight: 1.2 },
        h2: { variant: "headings.4", fontFamily: "heading", lineHeight: 1.2 },
        h3: { variant: "headings.5", fontFamily: "heading", lineHeight: 1.2 },
        h4: { variant: "headings.5", fontFamily: "heading", lineHeight: 1.2 },
        h5: { variant: "headings.5", fontFamily: "heading", lineHeight: 1.2 },
        a: {
          color: "text",
          textDecoration: "none",
          borderBottom: theme => "1px solid " + theme.colors.accent,
          "body.hasHover &:hover": {
            color: "accent",
          },
        },
        ".pullquote": {
          fontSize: "1.7em",
          lineHeight: "1.1em",
          fontWeight: "bold",
          my: [20, null, null, 30],
          mx: [0, 0, 0, -30],
        },
        img: {
          maxWidth: "100%",
          textAlign: "center",
        },
      },
    },
  },
  link: {
    default: {
      color: "inherit",
      textDecoration: "inherit",
      "body.hasHover &:hover": {
        opacity: 0.9,
      },
    },
    handle: {
      color: "inherit",
      fontFamily: "prose",
      fontSize: 4,
      "body.hasHover &:hover": {
        opacity: 0.9,
        color: "red",
      },
    },
    accent: {
      color: "accent",
      textDecoration: "inherit",
      "body.hasHover &:hover": {
        opacity: 0.9,
      },
    },
  },
  buttons: {
    transition: "all 250ms ease-in-out",
    primary: {
      background: "#FFFFFF",
      border: "2px solid #FC164B",
      color: "red",
      boxSizing: "border-box",
      borderRadius: 100,
      p: "0.5em 1em",
      fontSize: [20, null, 24],
      fontWeight: 500,
      fontFamily: "body",
      userSelect: "none",
      outline: "none",
      cursor: "pointer",
      "body.hasHover &:hover": {
        bg: "redTint",
      },
      ":hover": {
        opacity: 1,
        color: "text",
        border: "2px solid black",
        borderColor: "text",
        background: "rgba(255,255,255,0.9)",
      },
    },
    search: {
      background: "#FFFFFF",
      border: "2px solid #FC164B",
      color: "red",
      boxSizing: "border-box",
      borderRadius: 100,
      p: "0.5em 1em",
      fontSize: "1em",
      fontWeight: 500,
      fontFamily: "body",
      userSelect: "none",
      outline: "none",
      cursor: "pointer",
      "body.hasHover &:hover": {
        bg: "redTint",
      },
      ":hover": {
        opacity: 1,
        color: "text",
        border: "2px solid black",
        borderColor: "text",
        background: "rgba(255,255,255,0.9)",
      },
    },
    filled: {
      color: "white",
      bg: "accent",
      border: "none",
      boxSizing: "border-box",
      borderRadius: 100,
      p: "0.5em 1em",
      fontWeight: 500,
      fontFamily: "body",
      userSelect: "none",
      outline: "none",
      cursor: "pointer",
      "body.hasHover &:hover": {
        opacity: 0.8,
      },
    },
    wire: {
      color: "white",
      bg: "wireHex",
      border: "none",
      boxSizing: "border-box",
      borderRadius: 100,
      p: "0.5em 1em",
      fontWeight: 500,
      fontFamily: "body",
      userSelect: "none",
      outline: "none",
      cursor: "pointer",
      "body.hasHover &:hover": {
        opacity: 0.8,
      },
    },
    blueprint: {
      color: "white",
      bg: "blueprintHex",
      border: "none",
      boxSizing: "border-box",
      borderRadius: 100,
      p: "0.5em 1em",
      fontWeight: 500,
      fontFamily: "body",
      userSelect: "none",
      outline: "none",
      cursor: "pointer",
      "body.hasHover &:hover": {
        opacity: 0.8,
      },
    },
    movement: {
      color: "white",
      bg: "movementHex",
      border: "none",
      boxSizing: "border-box",
      borderRadius: 100,
      p: "0.5em 1em",
      fontWeight: 500,
      fontFamily: "body",
      userSelect: "none",
      outline: "none",
      cursor: "pointer",
      "body.hasHover &:hover": {
        opacity: 0.8,
      },
    },
    outlined: {
      background: "rgba(0,0,0,0)",
      border: "2px solid white",
      boxSizing: "border-box",
      borderRadius: 100,
      p: "0.5em 1em",
      fontSize: [20, null, 24],
      fontWeight: 500,
      fontFamily: "body",
      userSelect: "none",
      outline: "none",
      cursor: "pointer",
      "body.hasHover &:hover": {
        opacity: 1,
        color: "text",
        borderColor: "text",
        background: "white",
      },
    },
    plain: {
      backgroundColor: "transparent",
      boxSizing: "border-box",
      fontSize: "2em",
      fontWeight: 500,
      p: 0,
      fontFamily: "'IBM Plex Mono',Menlo,monospace",
      userSelect: "none",
      outline: "none",
      cursor: "pointer",
      color: "#111",
      "body.hasHover &:hover": {
        fontWeight: 600,
      },
    },
  },
  forms: {
    heading: {
      variant: "headings.3",
      marginBottom: "0.5em",
      "* + &": {
        marginTop: "1em",
      },
    },
    error: {
      color: "red",
      fontWeight: 500,
      mt: "0.1em",
      mb: "0.1em",
    },
    errorInverted: {
      color: "white",
      fontWeight: 500,
      mt: "0.1em",
      mb: "0.1em",
      fontSize: "0.65em",
    },
    label: {
      color: "black",
      transition: "all 250ms ease-in-out",
      variant: "headings.5",
      marginTop: "1.25em",
      marginBottom: 1,
      checkboxOption: {
        display: "block",
        margin: 2,
        marginLeft: 0,
        marginRight: 0,
        cursor: "pointer",
        fontWeight: "400 !important",
        fontSize: "inherit !important",
        letterSpacing: "initial",
        "&:hover input": {
          bg: "redTint",
        },
        "&:active input": {
          bg: "darkred",
          borderColor: "darkred",
        },
      },
    },
    input: {
      color: "black",
      fontFamily: "body",
      fontSize: "inherit",
      px: 0,
      py: "0.5em",
      bg: "rgba(0,0,0,0)",
      transition: "border-color 250ms ease-in-out",
      borderRadius: 0,
      border: "none",
      borderBottom: theme => "2px solid " + theme.colors.black,
      "&:focus": {
        borderColor: "secondary",
        outline: "none",
      },
    },
    checkbox: {
      cursor: "pointer",
      "-webkit-appearance": "none",
      "-moz-appearance": "none",
      "vertical-align": "middle",
      "font-size": "60px",
      background: "white",
      border: "2px solid red",
      width: "25px",
      height: "25px",
      "border-radius": "20%",
      display: "inline-flex",
      "justify-content": "center",
      "align-items": "center",
      "flex-shrink": "0",
      "line-height": "1em",
      "margin-left": "0",
      "margin-right": "5px",
      "&:disabled": {
        opacity: "0.25",
        cursor: "not-allowed",
      },
      "&:checked:after": {
        content: '""',
        display: "block",
        bg: "red",
        "border-radius": "20%",
        transform: "translate(0, 0)",
        height: "70%",
        width: "70%",
        "line-height": "0",
      },
    },
    checkboxInverted: {
      cursor: "pointer",
      "-webkit-appearance": "none",
      "-moz-appearance": "none",
      "vertical-align": "text-bottom",
      "font-size": "60px",
      background: "white",
      border: "none",
      width: "25px",
      height: "25px",
      "border-radius": "20%",
      display: "inline-flex",
      "justify-content": "center",
      "align-items": "center",
      "flex-shrink": "0",
      "line-height": "1em",
      "margin-left": "0",
      "margin-right": "5px",
      "&:disabled": {
        opacity: "0.25",
        cursor: "not-allowed",
      },
      "&:checked:after": {
        content: '""',
        display: "block",
        bg: "red",
        "border-radius": "20%",
        transform: "translate(0, 0)",
        height: "70%",
        width: "70%",
        "line-height": "0",
      },
    },
    radio: {
      cursor: "pointer",
      "-webkit-appearance": "none",
      "-moz-appearance": "none",
      "vertical-align": "middle",
      "font-size": "60px",
      background: "white",
      border: "2px solid red",
      width: "25px",
      height: "25px",
      "border-radius": "100%",
      display: "inline-flex",
      "justify-content": "center",
      "align-items": "center",
      "flex-shrink": "0",
      "line-height": "1em",
      "margin-left": "0",
      "margin-right": "5px",
      "&:disabled": {
        opacity: "0.25",
        cursor: "not-allowed",
      },
      "&:checked:after": {
        content: '""',
        display: "block",
        bg: "red",
        "border-radius": "100%",
        transform: "translate(0, 0)",
        height: "70%",
        width: "70%",
        "line-height": "0",
      },
    },
  },
  headings: {
    display: {
      m: 0,
      fontWeight: 600,
      fontSize: [32, 54],
      lineHeight: "90%",
      letterSpacing: -0.5,
    },
    menu: {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: "90%",
      textTransform: "uppercase",
    },
    meta: {
      fontSize: 20,
      fontWeight: 300,
      lineHeight: "90%",
      textTransform: "uppercase",
    },
    1: {
      fontWeight: 600,
      fontSize: [24, 54],
      lineHeight: ["110%", null, "90%"],
      letterSpacing: -0.5,
    },
    2: {
      fontWeight: 600,
      fontSize: [22, 32],
      letterSpacing: -0.25,
    },
    3: {
      fontWeight: 600,
      fontSize: [20, 24],
      lineHeight: "110%",
      letterSpacing: -0.3,
    },
    4: {
      fontWeight: 600,
      fontSize: [18, 22],
      lineHeight: "110%",
      letterSpacing: -0.3,
    },
    5: {
      fontWeight: 600,
      fontSize: [16, 20],
      lineHeight: "110%",
      letterSpacing: -0.3,
    },
  },
}

export default theme

const fontStack = `
/** Neue Haas Unica - main */

@font-face {
  font-family: "Neue Haas";
  src: url("/fonts/NeueHaasGrotesk/NHaasGroteskDSPro-35XLt.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Neue Haas";
  src: url("/fonts/NeueHaasGrotesk/NHaasGroteskDSPro-55Rg.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Neue Haas";
  src: url("/fonts/NeueHaasGrotesk/NHaasGroteskDSPro-65Md.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
`

export const GlobalStyles: React.FC = () => (
  <Global
    styles={css`
      ${fontStack};
      html,
      body {
        -webkit-tap-highlight-color: transparent;
        margin: 0;
        padding: 0;
        min-height: 100%;
        background: ${theme.colors.background};
        font-family: ${theme.fonts.body};
        font-size: ${[theme.fontSizes[3]]}px;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 0;
      }
    `}
  />
)
